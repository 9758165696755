const comunas = [
  {
    "pais":"CL",
    "datos":
    [
      {
        region: "Región Metropolitana de Santiago",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "Tarapacá",
        comunas: [
          "Iquique",
          "Alto Hospicio",
          "Pozo Almonte",
          "Camiña",
          "Colchane",
          "Huara",
          "Pica"
        ]
      },
      {
        region: "Antofagasta",
        comunas: [
          "Antofagasta",
          "Mejillones",
          "Sierra Gorda",
          "Taltal",
          "Calama",
          "Ollagüe",
          "San Pedro de Atacama",
          "Tocopilla",
          "María Elena"
        ]
      },
      {
        region: "Atacama",
        comunas: [
          "Copiapó",
          "Caldera",
          "Tierra Amarilla",
          "Chañaral",
          "Diego de Almagro",
          "Vallenar",
          "Alto del Carmen",
          "Freirina",
          "Huasco"
        ]
      },
      {
        region: "Coquimbo",
        comunas: [
          "La Serena",
          "Coquimbo",
          "Andacollo",
          "La Higuera",
          "Paiguano",
          "Vicuña",
          "Illapel",
          "Canela",
          "Los Vilos",
          "Salamanca",
          "Ovalle",
          "Combarbalá",
          "Monte Patria",
          "Punitaqui",
          "Río Hurtado"
        ]
      },
      {
        region: "Valparaíso",
        comunas: [
          "Valparaíso",
          "Casablanca",
          "Concón",
          "Juan Fernández",
          "Puchuncaví",
          "Quintero",
          "Viña del Mar",
          "Isla de Pascua",
          "Los Andes",
          "Calle Larga",
          "Rinconada",
          "San Esteban",
          "La Ligua",
          "Cabildo",
          "Papudo",
          "Petorca",
          "Zapallar",
          "Quillota",
          "Calera",
          "Hijuelas",
          "La Cruz",
          "Nogales",
          "San Antonio",
          "Algarrobo",
          "Cartagena",
          "El Quisco",
          "El Tabo",
          "Santo Domingo",
          "San Felipe",
          "Catemu",
          "Llay Llay",
          "Panquehue",
          "Putaendo",
          "Santa María",
          "Quilpué",
          "Limache",
          "Olmué",
          "Villa Alemana"
        ]
      },
      {
        region: "Región del Libertador Gral. Bernardo O’Higgins",
        comunas: [
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
        ]
      },
      {
        region: "Región del Maule",
        comunas: [
          "Talca",
          "Constitución",
          "Curepto",
          "Empedrado",
          "Maule",
          "Pelarco",
          "Pencahue",
          "Río Claro",
          "San Clemente",
          "San Rafael",
          "Cauquenes",
          "Chanco",
          "Pelluhue",
          "Curicó",
          "Hualañé",
          "Licantén",
          "Molina",
          "Rauco",
          "Romeral",
          "Sagrada Familia",
          "Teno",
          "Vichuquén",
          "Linares",
          "Colbún",
          "Longaví",
          "Parral",
          "Retiro",
          "San Javier de Loncomilla",
          "Villa Alegre",
          "Yerbas Buenas"
        ]
      },
      {
        region: "Región del Biobío",
        comunas: [
          "Concepción",
          "Coronel",
          "Chiguayante",
          "Florida",
          "Hualqui",
          "Lota",
          "Penco",
          "San Pedro de la Paz",
          "Santa Juana",
          "Talcahuano",
          "Tomé",
          "Hualpén",
          "Lebu",
          "Arauco",
          "Cañete",
          "Contulmo",
          "Curanilahue",
          "Los Álamos",
          "Tirúa",
          "Los Ángeles",
          "Antuco",
          "Cabrero",
          "Laja",
          "Mulchén",
          "Nacimiento",
          "Negrete",
          "Quilaco",
          "Quilleco",
          "San Rosendo",
          "Santa Bárbara",
          "Tucapel",
          "Yumbel",
          "Alto Biobío"
        ]
      },
      {
        region: "Región de la Araucanía",
        comunas: [
          "Temuco",
          "Carahue",
          "Cunco",
          "Curarrehue",
          "Freire",
          "Galvarino",
          "Gorbea",
          "Lautaro",
          "Loncoche",
          "Melipeuco",
          "Nueva Imperial",
          "Padre las Casas",
          "Perquenco",
          "Pitrufquén",
          "Pucón",
          "Saavedra",
          "Teodoro Schmidt",
          "Toltén",
          "Vilcún",
          "Villarrica",
          "Cholchol",
          "Angol",
          "Collipulli",
          "Curacautín",
          "Ercilla",
          "Lonquimay",
          "Los Sauces",
          "Lumaco",
          "Purén",
          "Renaico",
          "Traiguén",
          "Victoria"
        ]
      },
      {
        region: "Región de Los Ríos",
        comunas: [
          "Valdivia",
          "Corral",
          "Lanco",
          "Los Lagos",
          "Máfil",
          "Mariquina",
          "Paillaco",
          "Panguipulli",
          "La Unión",
          "Futrono",
          "Lago Ranco",
          "Río Bueno"
        ]
      },
      {
        region: "Región de Los Lagos",
        comunas: [
          "Puerto Montt",
          "Calbuco",
          "Cochamó",
          "Fresia",
          "Frutillar",
          "Los Muermos",
          "Llanquihue",
          "Maullín",
          "Puerto Varas",
          "Castro",
          "Ancud",
          "Chonchi",
          "Curaco de Vélez",
          "Dalcahue",
          "Puqueldón",
          "Queilén",
          "Quellón",
          "Quemchi",
          "Quinchao",
          "Osorno",
          "Puerto Octay",
          "Purranque",
          "Puyehue",
          "Río Negro",
          "San Juan de la Costa",
          "San Pablo",
          "Chaitén",
          "Futaleufú",
          "Hualaihué",
          "Palena"
        ]
      },
      {
        region: "Región Aisén del Gral. Carlos Ibáñez del Campo",
        comunas: [
          "Coyhaique",
          "Lago Verde",
          "Aisén",
          "Cisnes",
          "Guaitecas",
          "Cochrane",
          "O’Higgins",
          "Tortel",
          "Chile Chico",
          "Río Ibáñez"
        ]
      },
      {
        region: "Región de Magallanes y de la Antártica Chilena",
        comunas: [
          "Punta Arenas",
          "Laguna Blanca",
          "Río Verde",
          "San Gregorio",
          "Cabo de Hornos (Ex Navarino)",
          "Antártica",
          "Porvenir",
          "Primavera",
          "Timaukel",
          "Natales",
          "Torres del Paine"
        ]
      },
      {
        region: "Arica y Parinacota",
        comunas: ["Arica", "Camarones", "Putre", "General Lagos"]
      },
      {
        region: "Región de Ñuble",
        comunas: [
          "Cobquecura",
          "Coelemu",
          "Ninhue",
          "Portezuelo",
          "Quirihue",
          "Ránquil",
          "Treguaco",
          "Bulnes",
          "Chillán Viejo",
          "Chillán",
          "El Carmen",
          "Pemuco",
          "Pinto",
          "Quillón",
          "San Ignacio",
          "Yungay",
          "Coihueco",
          "Ñiquén",
          "San Carlos",
          "San Fabián",
          "San Nicolás"
        ]
      },
      {
        region: "Los Lagos",
        comunas: [
          "Puerto Montt",
          "Calbuco",
          "Cochamó",
          "Fresia",
          "Frutillar",
          "Los Muermos",
          "Llanquihue",
          "Maullín",
          "Puerto Varas",
          "Castro",
          "Ancud",
          "Chonchi",
          "Curaco de Vélez",
          "Dalcahue",
          "Puqueldón",
          "Queilén",
          "Quellón",
          "Quemchi",
          "Quinchao",
          "Osorno",
          "Puerto Octay",
          "Purranque",
          "Puyehue",
          "Río Negro",
          "San Juan de la Costa",
          "San Pablo",
          "Chaitén",
          "Futaleufú",
          "Hualaihué",
          "Palena"
        ]
      },
      {
        region: "Aisén",
        comunas: [
          "Coihaique",
          "Lago Verde",
          "Aisén",
          "Cisnes",
          "Guaitecas",
          "Cochrane",
          "O’Higgins",
          "Tortel",
          "Chile Chico",
          "Río Ibáñez"
        ]
      },
      {
        region: "Araucanía",
        comunas: [
          "Temuco",
          "Carahue",
          "Cunco",
          "Curarrehue",
          "Freire",
          "Galvarino",
          "Gorbea",
          "Lautaro",
          "Loncoche",
          "Melipeuco",
          "Nueva Imperial",
          "Padre las Casas",
          "Perquenco",
          "Pitrufquén",
          "Pucón",
          "Saavedra",
          "Teodoro Schmidt",
          "Toltén",
          "Vilcún",
          "Villarrica",
          "Cholchol",
          "Angol",
          "Collipulli",
          "Curacautín",
          "Ercilla",
          "Lonquimay",
          "Los Sauces",
          "Lumaco",
          "Purén",
          "Renaico",
          "Traiguén",
          "Victoria"
        ]
      },
      {
        region: "Biobío",
        comunas: [
          "Concepción",
          "Coronel",
          "Chiguayante",
          "Florida",
          "Hualqui",
          "Lota",
          "Penco",
          "San Pedro de la Paz",
          "Santa Juana",
          "Talcahuano",
          "Tomé",
          "Hualpén",
          "Lebu",
          "Arauco",
          "Cañete",
          "Contulmo",
          "Curanilahue",
          "Los Álamos",
          "Tirúa",
          "Los Ángeles",
          "Antuco",
          "Cabrero",
          "Laja",
          "Mulchén",
          "Nacimiento",
          "Negrete",
          "Quilaco",
          "Quilleco",
          "San Rosendo",
          "Santa Bárbara",
          "Tucapel",
          "Yumbel",
          "Alto Biobío"
        ]
      },
      {
        region: "Libertador B. O'Higgins",
        comunas: [
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
        ]
      },
      {
        region: "Los Ríos",
        comunas: [
          "Valdivia",
          "Corral",
          "Lanco",
          "Los Lagos",
          "Máfil",
          "Mariquina",
          "Paillaco",
          "Panguipulli",
          "La Unión",
          "Futrono",
          "Lago Ranco",
          "Río Bueno"
        ]
      },
      {
        region: "Magallanes",
        comunas: [
          "Punta Arenas",
          "Laguna Blanca",
          "Río Verde",
          "San Gregorio",
          "Cabo de Hornos (Ex Navarino)",
          "Antártica",
          "Porvenir",
          "Primavera",
          "Timaukel",
          "Natales",
          "Torres del Paine"
        ]
      },
      {
        region: "Maule",
        comunas: [
          "Talca",
          "Constitución",
          "Curepto",
          "Empedrado",
          "Maule",
          "Pelarco",
          "Pencahue",
          "Río Claro",
          "San Clemente",
          "San Rafael",
          "Cauquenes",
          "Chanco",
          "Pelluhue",
          "Curicó",
          "Hualañé",
          "Licantén",
          "Molina",
          "Rauco",
          "Romeral",
          "Sagrada Familia",
          "Teno",
          "Vichuquén",
          "Linares",
          "Colbún",
          "Longaví",
          "Parral",
          "Retiro",
          "San Javier de Loncomilla",
          "Villa Alegre",
          "Yerbas Buenas"
        ]
      },
      {
        region: "Metropolitana",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "METROPOLITANA DE SANTIAGO",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "Ñuble",
        comunas: [
          "Cobquecura",
          "Coelemu",
          "Ninhue",
          "Portezuelo",
          "Quirihue",
          "Ránquil",
          "Treguaco",
          "Bulnes",
          "Chillán Viejo",
          "Chillán",
          "El Carmen",
          "Pemuco",
          "Pinto",
          "Quillón",
          "San Ignacio",
          "Yungay",
          "Coihueco",
          "Ñiquén",
          "San Carlos",
          "San Fabián",
          "San Nicolás"
        ]
      },
      {
        region: "O'Higgins",
        comunas: [
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
        ]
      },
      {
        region: "Region metropolitana",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
      {
        region: "RM (Metropolitana)",
        comunas: [
          "Cerrillos",
          "Cerro Navia",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
        ]
      },
    ]
  },
  {
    "pais":"CRI",
    "datos":
    [
      {
         "region":"ESCAZU",
         "comunas":[
            "SAN RAFAEL",
            "ESCAZU",
            "SAN ANTONIO"
         ]
      },
      {
         "region":"HEREDIA",
         "comunas":[
            "HEREDIA",
            "SAN FRANCISCO",
            "ULLOA",
            "MERCEDES",
            "VARABLANCA"
         ]
      },
      {
         "region":"SAN PABLO",
         "comunas":[
            "RINCON DE SABANILLA",
            "SAN PABLO"
         ]
      },
      {
         "region":"CURRIDABAT",
         "comunas":[
            "CURRIDABAT",
            "GRANADILLA",
            "SANCHEZ",
            "TIRRASES"
         ]
      },
      {
         "region":"SAN JOSE",
         "comunas":[
            "ZAPOTE",
            "MERCED",
            "CARMEN",
            "SAN SEBASTIAN",
            "SAN FRANCISCO DE DOS RIOS",
            "HOSPITAL",
            "MATA REDONDA",
            "PAVAS",
            "URUCA",
            "HATILLO",
            "CATEDRAL"
         ]
      },
      {
         "region":"ASERRI",
         "comunas":[
            "ASERRI",
            "SALITRILLOS",
            "TARBACA",
            "SAN GABRIEL",
            "VUELTA DE JORCO",
            "MONTERREY",
            "LEGUA"
         ]
      },
      {
         "region":"DESAMPARADOS",
         "comunas":[
            "DESAMPARADOS",
            "GRAVILIAS",
            "SAN RAFAEL ARRIBA",
            "SAN RAFAEL ABAJO",
            "SAN JUAN DE DIOS",
            "SAN MIGUEL",
            "DAMAS",
            "LOS GUIDO",
            "SAN ANTONIO",
            "PATARRA",
            "ROSARIO",
            "FRAILES",
            "SAN CRISTOBAL"
         ]
      },
      {
         "region":"MORAVIA",
         "comunas":[
            "SAN VICENTE",
            "LA TRINIDAD",
            "SAN JERONIMO"
         ]
      },
      {
         "region":"ALAJUELA",
         "comunas":[
            "ALAJUELA",
            "SAN ANTONIO",
            "RIO SEGUNDO",
            "DESAMPARADOS",
            "SAN RAFAEL",
            "GUACIMA",
            "TAMBOR",
            "GARITA",
            "SABANILLA",
            "CARRIZAL",
            "TURRUCARES",
            "SAN ISIDRO",
            "SAN JOSE",
            "SARAPIQUI"
         ]
      },
      {
         "region":"ALAJUELITA",
         "comunas":[
            "ALAJUELITA",
            "CONCEPCION",
            "SAN ANTONIO",
            "SAN JOSECITO",
            "SAN FELIPE"
         ]
      },
      {
         "region":"TIBAS",
         "comunas":[
            "ANSELMO LLORENTE",
            "COLIMA",
            "CINCO ESQUINAS",
            "SAN JUAN",
            "LEON XIII"
         ]
      },
      {
         "region":"CARTAGO",
         "comunas":[
            "GUADALUPE (ARENILLA)",
            "OCCIDENTAL",
            "ORIENTAL",
            "AGUACALIENTE (SAN FRANCISCO)",
            "CARMEN",
            "SAN NICOLAS",
            "DULCE NOMBRE",
            "LLANO GRANDE",
            "CORRALILLO",
            "TIERRA BLANCA"
         ]
      },
      {
         "region":"MONTES DE OCA",
         "comunas":[
            "SAN PEDRO",
            "SAN RAFAEL",
            "MERCEDES",
            "SABANILLA"
         ]
      },
      {
         "region":"SANTA BARBARA",
         "comunas":[
            "SANTO DOMINGO",
            "JESUS",
            "SAN JUAN",
            "PURABA",
            "SANTA BARBARA",
            "SAN PEDRO"
         ]
      },
      {
         "region":"SAN RAFAEL",
         "comunas":[
            "SANTIAGO",
            "SAN JOSECITO",
            "SAN RAFAEL",
            "ANGELES",
            "CONCEPCION"
         ]
      },
      {
         "region":"GOICOECHEA",
         "comunas":[
            "CALLE BLANCOS",
            "GUADALUPE",
            "MATA DE PLATANO",
            "IPIS",
            "PURRAL",
            "SAN FRANCISCO",
            "RANCHO REDONDO"
         ]
      },
      {
         "region":"BARVA",
         "comunas":[
            "SAN PABLO",
            "SANTA LUCIA",
            "SAN PEDRO",
            "BARVA",
            "SAN ROQUE",
            "SAN JOSE DE LA MONTAÑA"
         ]
      },
      {
         "region":"MONTES DE OCA ",
         "comunas":[
            "SABANILLA",
            "SAN PEDRO"
         ]
      },
      {
         "region":"SANTO DOMINGO",
         "comunas":[
            "SANTA ROSA",
            "SANTO DOMINGO",
            "SAN MIGUEL",
            "TURES",
            "SAN VICENTE",
            "SANTO TOMAS",
            "PARA",
            "PARACITO"
         ]
      },
      {
         "region":"SAN ISIDRO",
         "comunas":[
            "SAN FRANCISCO",
            "SAN JOSE",
            "SAN ISIDRO",
            "CONCEPCION"
         ]
      },
      {
         "region":"EL GUARCO",
         "comunas":[
            "EL TEJAR",
            "SAN ISIDRO",
            "PATIO DE AGUA",
            "TOBOSI"
         ]
      },
      {
         "region":"LA UNION",
         "comunas":[
            "TRES RIOS",
            "SAN RAFAEL",
            "SAN DIEGO",
            "RIO AZUL",
            "CONCEPCION",
            "DULCE NOMBRE",
            "SAN JUAN",
            "SAN RAMON"
         ]
      },
      {
         "region":"VAZQUEZ DE CORONADO",
         "comunas":[
            "SAN ISIDRO",
            "PATALILLO",
            "SAN RAFAEL",
            "CASCAJAL",
            "DULCE NOMBRE DE JESUS"
         ]
      },
      {
         "region":"OREAMUNO",
         "comunas":[
            "SAN RAFAEL",
            "CIPRESES",
            "COT",
            "POTRERO CERRADO",
            "SANTA ROSA"
         ]
      },
      {
         "region":"SANTA ANA",
         "comunas":[
            "POZOS",
            "SANTA ANA",
            "SALITRAL",
            "PIEDADES",
            "URUCA",
            "BRASIL"
         ]
      },
      {
         "region":"FLORES",
         "comunas":[
            "SAN JOAQUIN",
            "BARRANTES",
            "LLORENTE"
         ]
      },
      {
         "region":"PARAISO",
         "comunas":[
            "PARAISO",
            "CACHI",
            "OROSI",
            "SANTIAGO"
         ]
      },
      {
         "region":"BELEN",
         "comunas":[
            "LA ASUNCION",
            "LA RIBERA",
            "SAN ANTONIO"
         ]
      },
      {
         "region":"MORA",
         "comunas":[
            "COLON",
            "JARIS",
            "QUITIRRISI",
            "GUAYABO",
            "TABARCIA",
            "PIEDRAS NEGRAS",
            "PICAGRES"
         ]
      },
      {
         "region":"ALVARADO",
         "comunas":[
            "CERVANTES",
            "PACAYAS",
            "CAPELLADES"
         ]
      },
      {
         "region":"ACOSTA",
         "comunas":[
            "CANGREJAL",
            "GUAITIL",
            "PALMICHAL",
            "SABANILLAS",
            "SAN IGNACIO"
         ]
      },
      {
         "region":"JIMENEZ",
         "comunas":[
            "JUAN VIÑAS",
            "TUCURRIQUE",
            "PEJIBAYE"
         ]
      },
      {
         "region":"DOTA",
         "comunas":[
            "COPEY",
            "JARDIN",
            "SANTA MARIA"
         ]
      },
      {
         "region":"TURRIALBA",
         "comunas":[
            "SANTA CRUZ",
            "CHIRRIPO",
            "SANTA ROSA",
            "LA ISABEL",
            "PAVONES",
            "LA SUIZA",
            "PERALTA",
            "SANTA TERESITA",
            "TAYUTIC",
            "TRES EQUIS",
            "TUIS",
            "TURRIALBA"
         ]
      },
      {
         "region":"PURISCAL",
         "comunas":[
            "BARBACOAS",
            "CANDELARITA",
            "CHIRES",
            "DESAMPARADITOS",
            "GRIFO ALTO",
            "MERCEDES SUR",
            "SAN ANTONIO",
            "SAN RAFAEL",
            "SANTIAGO"
         ]
      },
      {
         "region":"RIO CUARTO",
         "comunas":[
            "RIO CUARTO",
            "SANTA RITA",
            "SANTA ISABEL"
         ]
      },
      {
         "region":"SARAPIQUI",
         "comunas":[
            "CUREÑA",
            "LA VIRGEN",
            "LAS HORQUETAS",
            "LLANURAS DEL GASPAR",
            "PUERTO VIEJO"
         ]
      },
      {
         "region":"GRECIA",
         "comunas":[
            "BOLIVAR",
            "GRECIA",
            "PUENTE DE PIEDRA",
            "SAN ISIDRO",
            "SAN JOSE",
            "SAN ROQUE",
            "TACARES"
         ]
      },
      {
         "region":"GUATUSO",
         "comunas":[
            "BUENAVISTA",
            "COTE",
            "KATIRA",
            "SAN RAFAEL"
         ]
      },
      {
         "region":"LOS CHILES",
         "comunas":[
            "CAÑO NEGRO",
            "EL AMPARO",
            "LOS CHILES",
            "SAN JORGE"
         ]
      },
      {
         "region":"NARANJO",
         "comunas":[
            "CIRRI SUR",
            "EL ROSARIO",
            "NARANJO",
            "PALMITOS",
            "SAN JERONIMO",
            "SAN JOSE",
            "SAN JUAN",
            "SAN MIGUEL"
         ]
      },
      {
         "region":"PALMARES",
         "comunas":[
            "BUENOS AIRES",
            "CANDELARIA",
            "ESQUIPULAS",
            "LA GRANJA",
            "PALMARES",
            "SANTIAGO",
            "ZARAGOZA"
         ]
      },
      {
         "region":"SAN CARLOS",
         "comunas":[
            "CUTRIS",
            "FLORENCIA",
            "LA FORTUNA",
            "LA PALMERA",
            "MONTERREY",
            "PITAL",
            "POCOSOL",
            "VENADO",
            "VENECIA",
            "AGUAS ZARCAS",
            "BUENAVISTA",
            "LA TIGRA",
            "QUESADA"
         ]
      },
      {
         "region":"SAN RAMON",
         "comunas":[
            "ALFARO",
            "ANGELES",
            "CONCEPCION",
            "PEÑAS BLANCAS",
            "PIEDADES NORTE",
            "PIEDADES SUR",
            "SAN ISIDRO",
            "SAN JUAN",
            "SAN LORENZO",
            "SAN RAFAEL",
            "SAN RAMON",
            "SANTIAGO",
            "VOLIO",
            "ZAPOTAL"
         ]
      },
      {
         "region":"VALVERDE VEGA",
         "comunas":[
            "RODRIGUEZ",
            "SAN PEDRO",
            "SARCHI NORTE",
            "SARCHI SUR",
            "TORO AMARILLO"
         ]
      },
      {
         "region":"ZARCERO",
         "comunas":[
            "BRISAS",
            "GUADALUPE",
            "LAGUNA",
            "PALMIRA",
            "TAPEZCO",
            "ZAPOTE",
            "ZARCERO"
         ]
      },
      {
         "region":"TURRUBARES",
         "comunas":[
            "CARARA",
            "SAN JUAN DE MATA",
            "SAN LUIS",
            "SAN PABLO",
            "SAN PEDRO"
         ]
      },
      {
         "region":"ATENAS",
         "comunas":[
            "ATENAS",
            "CONCEPCION",
            "ESCOBAL",
            "JESUS",
            "MERCEDES",
            "SAN ISIDRO",
            "SAN JOSE",
            "SANTA EULALIA"
         ]
      },
      {
         "region":"GARABITO",
         "comuna":[
            "TARCOLES",
            "JACO"
         ]
      },
      {
         "region":"OROTINA",
         "comunas":[
            "COYOLAR",
            "EL MASTATE",
            "HACIENDA VIEJA",
            "LA CEIBA",
            "OROTINA"
         ]
      },
      {
         "region":"POAS",
         "comunas":[
            "SABANA REDONDA",
            "CARRILLOS",
            "SAN JUAN",
            "SAN PEDRO",
            "SAN RAFAEL"
         ]
      },
      {
         "region":"SAN MATEO",
         "comunas":[
            "DESMONTE",
            "JESUS MARIA",
            "LABRADOR",
            "SAN MATEO"
         ]
      },
      {
         "region":"ABANGARES",
         "comunas":[
            "COLORADO",
            "LAS JUNTAS",
            "SAN JUAN",
            "SIERRA"
         ]
      },
      {
         "region":"CAÑAS",
         "comunas":[
            "POROZAL",
            "BEBEDERO",
            "CAÑAS",
            "PALMIRA",
            "SAN MIGUEL"
         ]
      },
      {
         "region":"ESPARZA",
         "comunas":[
            "CALDERA",
            "ESPIRITU SANTO",
            "MACACONA",
            "SAN JERONIMO",
            "SAN JUAN GRANDE",
            "SAN RAFAEL"
         ]
      },
      {
         "region":"MONTES DE ORO",
         "comunas":[
            "LA UNION",
            "MIRAMAR",
            "SAN ISIDRO"
         ]
      },
      {
         "region":"PUNTARENAS",
         "comunas":[
            "ACAPULCO",
            "ARANCIBIA",
            "BARRANCA",
            "CHACARITA",
            "CHIRA",
            "CHOMES",
            "COBANO",
            "EL ROBLE",
            "GUACIMAL",
            "ISLA DEL COCO",
            "LEPANTO",
            "MANZANILLO",
            "MONTE VERDE",
            "PAQUERA",
            "PITAHAYA",
            "PUNTARENAS"
         ]
      },
      {
         "region":"PUNTARENAS ",
         "comunas":[
            "BARRANCA",
            "EL ROBLE"
         ]
      },
      {
         "region":"UPALA",
         "comunas":[
            "AGUAS CLARAS",
            "BIJAGUA",
            "CANALETE",
            "DELICIAS",
            "DOS RIOS",
            "SAN JOSE O PIZOTE",
            "UPALA",
            "YOLILLAL"
         ]
      },
      {
         "region":"BAGACES",
         "comunas":[
            "BAGACES",
            "LA FORTUNA",
            "MOGOTE",
            "RIO NARANJO"
         ]
      },
      {
         "region":"LIBERIA",
         "comunas":[
            "LIBERIA",
            "CAÑAS DULCES",
            "CURUBANDE",
            "MAYORGA",
            "NACASCOLO"
         ]
      },
      {
         "region":"TILARAN",
         "comunas":[
            "ARENAL",
            "LIBANO",
            "QUEBRADA GRANDE",
            "SANTA ROSA",
            "TIERRAS MORENAS",
            "TILARAN",
            "TRONADORA"
         ]
      },
      {
         "region":"LA CRUZ",
         "comunas":[
            "SANTA CECILIA",
            "LA CRUZ",
            "LA GARITA",
            "SANTA ELENA"
         ]
      },
      {
         "region":"CARRILLO",
         "comunas":[
            "BELEN",
            "FILADELFIA",
            "PALMIRA",
            "SARDINAL"
         ]
      },
      {
         "region":"HOJANCHA",
         "comunas":[
            "HOJANCHA",
            "HUACAS",
            "MONTE ROMO",
            "PUERTO CARRILLO"
         ]
      },
      {
         "region":"NANDAYURE",
         "comunas":[
            "BEJUCO",
            "CARMONA",
            "PORVENIR",
            "SAN PABLO",
            "SANTA RITA",
            "ZAPOTAL"
         ]
      },
      {
         "region":"NICOYA",
         "comunas":[
            "BELEN DE NOSARITA",
            "MANSION",
            "NICOYA",
            "NOSARA",
            "QUEBRADA HONDA",
            "SAMARA",
            "SAN ANTONIO"
         ]
      },
      {
         "region":"SANTA CRUZ",
         "comunas":[
            "BOLSON",
            "CABO VELAS",
            "CARTAGENA",
            "CUAJINIQUIL",
            "DIRIA",
            "SANTA CRUZ",
            "TAMARINDO",
            "TEMPATE",
            "VEINTISIETE DE ABRIL"
         ]
      },
      {
         "region":"BUENOS AIRES",
         "comunas":[
            "BIOLLEY",
            "BORUCA",
            "BRUNKA",
            "BUENOS AIRES",
            "CHANGUENA",
            "COLINAS",
            "PILAS",
            "POTRERO GRANDE",
            "VOLCAN"
         ]
      },
      {
         "region":"CORREDORES",
         "comunas":[
            "CANOAS",
            "CORREDOR",
            "LA CUESTA",
            "LAUREL"
         ]
      },
      {
         "region":"COTO BRUS",
         "comunas":[
            "AGUA BUENA",
            "GUTIERREZ BRAUN",
            "LIMONCITO",
            "PITTIER",
            "SABALITO",
            "SAN VITO"
         ]
      },
      {
         "region":"GOLFITO",
         "comunas":[
            "GOLFITO",
            "GUAYCARA",
            "PAVON",
            "PUERTO JIMENEZ"
         ]
      },
      {
         "region":"GUACIMO",
         "comunas":[
            "DUACARI",
            "GUACIMO",
            "MERCEDES",
            "POCORA",
            "RIO JIMENEZ"
         ]
      },
      {
         "region":"LEON CORTES",
         "comunas":[
            "LLANO BONITO",
            "SAN ANDRES",
            "SAN ANTONIO",
            "SAN ISIDRO",
            "SAN PABLO",
            "SANTA CRUZ"
         ]
      },
      {
         "region":"LIMON",
         "comunas":[
            "LIMON",
            "MATAMA",
            "RIO BLANCO",
            "VALLE DE LA ESTRELLA"
         ]
      },
      {
         "region":"MATINA",
         "comunas":[
            "BATAN",
            "CARRANDI",
            "MATINA"
         ]
      },
      {
         "region":"OSA",
         "comunas":[
            "BAHIA BALLENA",
            "BAHIA DRAKE",
            "PALMAR",
            "PIEDRAS BLANCAS",
            "PUERTO CORTES",
            "SIERPE"
         ]
      },
      {
         "region":"PARRITA",
         "comunas":[
            "PARRITA"
         ]
      },
      {
         "region":"PEREZ ZELEDON",
         "comunas":[
            "BARU",
            "CAJON",
            "DANIEL FLORES",
            "EL GENERAL",
            "LA AMISTAD",
            "PARAMO",
            "PEJIBAYE",
            "PLATANARES",
            "RIO NUEVO",
            "RIVAS",
            "SAN ISIDRO DE EL GENERAL",
            "SAN PEDRO"
         ]
      },
      {
         "region":"POCOCI",
         "comunas":[
            "CARIARI",
            "COLORADO",
            "GUAPILES",
            "JIMENEZ",
            "LA COLONIA",
            "RITA",
            "ROXANA"
         ]
      },
      {
         "region":"QUEPOS",
         "comunas":[
            "NARANJITO",
            "QUEPOS",
            "SAVEGRE"
         ]
      },
      {
         "region":"SIQUIRRES",
         "comunas":[
            "EL CAIRO",
            "FLORIDA",
            "GERMANIA",
            "LA ALEGRIA",
            "PACUARITO",
            "SIQUIRRES"
         ]
      },
      {
         "region":"TALAMANCA",
         "comunas":[
            "BRATSI",
            "CAHUITA",
            "SIXAOLA",
            "TELIRE"
         ]
      },
      {
         "region":"TARRAZU",
         "comunas":[
            "SAN CARLOS",
            "SAN LORENZO",
            "SAN MARCOS"
         ]
      }
    ]
  }
];

export default comunas;